/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import flatpickr from 'flatpickr';
import MicroModal from 'micromodal';
import Rails from '@rails/ujs';
Rails.start();

import Turbolinks from 'turbolinks';
Turbolinks.start();
Turbolinks.setProgressBarDelay(200);

import * as ActiveStorage from '@rails/activestorage';
import { DirectUpload } from '@rails/activestorage';
ActiveStorage.start();

import * as moment from 'moment';

var ActionCable = require('@rails/actioncable');

var App = App || {};

function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

// Updates the UI when scores come back
function showUpdatedScore(data) {
  var json = JSON.parse(data);
  var score = parseFloat(json.score).toFixed(2) + '%';

  // Update risk score on left panel
  var riskScore = 'tr[data-result-id="' + json.sample_set_id + '"] .patient-list__risk a';
  $(riskScore).html(score);

  var rowToUpdate = 'tr[data-result-id="' + json.sample_set_id + '"]';
  $(rowToUpdate).removeClass('is-invalid');

  // Update the details panel if this is the currently selected set
  var detailsScoreSelector = '.panel__risk-score[data-result-id="' + json.sample_set_id + '"] .risk-score-value';
  $(detailsScoreSelector).html(score);

  var detailsVersionSelector = '.panel__risk-score[data-result-id="' + json.sample_set_id + '"] .risk-score__alg td:nth-child(2)';
  $(detailsVersionSelector).text(json.version);

  var analysisDate = moment(json.created_at);

  var detailsDateSelector = '.panel__risk-score[data-result-id="' + json.sample_set_id + '"] .risk-score__last-date td:nth-child(2)';
  $(detailsDateSelector).text(analysisDate.utc().format('MM/DD/YY HH:mm UTC'));
}

var alreadyConnected = false;

// Fix for button_to using a form and input instead of a button element
// This handles changing the text of the reset password button when resizing
// the window...
function checkWidth() {
  if ($('#reset-password').length) {
    if ($(window).width() < 1200) {
      $('#reset-password').prop('value', 'Reset Pass');
    }
    else {
      $('#reset-password').prop('value', 'Reset Password');
    }
  }
}

$(window).resize(checkWidth);

$(document).bind('turbolinks:load', function() {
  var userId = getCookieValue('user.id');

  // Connect for the first time
  if (userId && !alreadyConnected) {
    console.log('Websocket: Connected');
    App.cable = ActionCable.createConsumer();

    App.cable.subscriptions.create('ApplicationCable::ScoresChannel', {
      received: function(data) { showUpdatedScore(data); },
      disconnected: function(data) { console.log('Socket Disconnected'); },
      connected: function() { console.log('Connected'); }
    });
    alreadyConnected = true;
  }
  // Handle disconnect
  else if (!userId && alreadyConnected) {
    console.log('Websocket: Disconnected');

    delete App.cable;
    alreadyConnected = false;
  }

  // Change rest password button text
  checkWidth();

  // Login Errors - Triggers slide down of flash area on login page
  if ($('.flash__container > div').text().trim().length) {
    $('.flash__container').slideDown(250);
  }

  // FILTER BUTTON - Animate filter panel
  $('#panel__filter--button').click(function(e) {
    e.preventDefault();
    $('#panel__filter-controls').slideToggle(250);
  });

  // FILTER - Load Date Pickers
  var config = { dateFormat: 'm/d/Y' };
  var cachedStart = $('#filter_date_start').val();
  var cachedEnd = $('#filter_date_end').val();
  flatpickr('#filter_date_start', config);
  flatpickr('#filter_date_end', config);
  $('#filter_date_start').val(cachedStart);
  $('#filter_date_end').val(cachedEnd);

  // FILTER - Reset form
  $('#filter__reset').click(function() {
    // Only redirect if filter has been applied previously
    if (window.location.search) { Turbolinks.visit('/'); }
    // Filter hasn't been applied previously, just clear the fields
    else { clearFilter(); }
  });

  // FILTER - Rest Form from minus icon
  $('#filter__reset-trigger').click(function() { clearFilter(); });

  // FILTER - Manually clear fields
  function clearFilter() {
    $('#filter_patient_id').attr('value', '');
    $('#filter_date_start').attr('value', '');
    $('#filter_date_end').attr('value', '');
    $('#filter_risk_score').attr('value', '');
    $('#filter__form')[0].reset();
    $('#filter__submit').prop('disabled', true);
  }

  // FILTER - Validate form and Enable/Disable Submit
  $('#filter__form').find(':input').on('input', function() {
    var valid = $('#filter_patient_id').val() ||
                $('#filter_date_start').val() ||
                $('#filter_date_end').val() ||
                $('#filter_risk_score').val();
    $('#filter__submit').prop('disabled', !valid);
  });


  // NAVIGATION - Animate dropdown
  $('#nav__dropdown-user').hover(function() {
    $(this).toggleClass('expanded');
    $('#menu__dropdown-user').slideToggle('fast');
  });


  // NAVIGATION - Open mobile menu
  $('#mobile-menu__trigger, #mobile-menu__cover').click(function() {
    $('#menu-list').toggleClass('expanded');
    $('#mobile-menu__cover').toggleClass('expanded');
  });


  // MODAL ERRORS - Generic Handling of showing/hiding modal errors
  $('.modal__error').bind('DOMSubtreeModified', function() {
    var message = $(this).text();
    if (message.length) { $(this).slideDown(250); }
    else { $(this).hide(); }
  });


  // MESSAGE PANEL - Dismiss message
  var dismissMessage;
  $('#messages .panel__messages-close').click(function(e) {
    e.preventDefault();
    window.clearTimeout(dismissMessage);
    $('#messages').slideUp(250, function() {
      $(this).removeClass('error success info');
      $('#message-content').html('');
    });
  });

  // MESSAGE PANEL - Show message helper function
  var showMessage = function() {
    var message = $('#message-content').text();

    if (message.length) {
      // Clear old timeout if message is currently being shown
      if ($('#messages').is(':visible')) {  window.clearTimeout(dismissMessage); }
      // Otherwise show message area
      else { $('#messages').slideDown(250); }

      dismissMessage = window.setTimeout(function(){
        $('#messages').slideUp(250, function() {
          // Clear styles and message after dismissal
          $(this).removeClass('error success info');
          $('#message-content').html('');
        });
      }, 2500);
    }
  };

  // MESSAGE PANEL - Show message on page load if text is present
  if ($('#message-content').text().trim().length) { showMessage(); }

  // MESSAGE PANEL - Watch if message content changes, if it does show the message area
  $('#message-content').bind('DOMSubtreeModified', showMessage);


  /* Import Modal */
  // Submit Modal Form
  $('#modal__import-data .modal__btn-primary').click(function() {
    $(this).attr('disabled', true);
    $(this).html('Import <span class="show">Data</span><div class="spinner"></div>');

    var elem = $('#modal__import-data form')[0];
    Rails.fire(elem, 'submit');
  });

  $('#reset-password').click(function() {
    $(this).attr('disabled', true);
    $(this).parent().append('<div class="spinner"></div>');
    Rails.fire($(this)[0].parentElement, 'submit');
  });

  // UPLOAD FILES - Global Variables
  var uploadedFiles = [];
  var uploadedFileCount = 0;
  var hiddenInputIds = [];
  // UPLOAD FILES - Open Import Data Modal
  $(`[data-micromodal-trigger='modal__import-data']`).click(function () {
    // clear out any errors
    MicroModal.show('modal__import-data', {
      onClose: resetImportModal()
    });
  });

  // UPLOAD FILES - Close Import Data Modal
  $('#form__import-data').on('ajax:success', function() {
    MicroModal.close('modal__import-data');
  });

  // UPLOAD FILES - File Drop
  $('#modal__import-data-dropzone').on('drop', function(ev){
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // Clear Errors on Drop
    $('#modal__import-data .modal__error').html('');

    // Proccesses accepted files
    var process = function(file) {
      if (file.type === 'text/csv') {
        uploadedFileCount++;
        uploadFile(file);
      }
      else { $('#modal__import-data .modal__error').html('Only csv file types are allowed'); }
    };

    // Use DataTransferItemList interface to access the file(s)
    if (ev.originalEvent.dataTransfer.items) {
      for (var i = 0; i < ev.originalEvent.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.originalEvent.dataTransfer.items[i].kind === 'file') {
          process(ev.originalEvent.dataTransfer.items[i].getAsFile());
        }
      }
    }
    // Use DataTransfer interface to access the file(s)
    else {
      for (var i = 0; i < ev.originalEvent.dataTransfer.files.length; i++) {
        process(ev.originalEvent.dataTransfer.files[i].getAsFile());
      }
    }

    // Cleanup Dropped Files
    if (ev.originalEvent.dataTransfer.items) {  ev.originalEvent.dataTransfer.items.clear(); }
    else { ev.originalEvent.dataTransfer.clearData(); }
  });

  // UPLOAD FILES - Prevent File from opening on dragover
  $('#modal__import-data-dropzone').on('dragover', function(ev) {
    ev.preventDefault();
  });

  // UPLOAD FILES - File Input Field handle upload
  $('#import_data_files').change(function() {
    // Hide errors
    $('#modal__import-data .modal__error').html('');

    // Upload files
    var files = $(this).get(0).files;
    for (var i = 0; i < files.length; i++) {
      uploadedFileCount++;
      uploadFile(files[i]);
    }
  });

  // UPLOAD FILES - Remove files from upload input
  function removeFile() {
    var fileId = $(this).data('file-id');
    // Remove references to file
    $('#file-' + fileId).remove();
    $('li[data-file-id=' + fileId + ']').remove();
    $('#modal__import-data input[type="file"]').val('');
    uploadedFiles = uploadedFiles.filter(function(file) { return file.id !== fileId; });

    // If all files have been removed reset the modal
    if (uploadedFiles.length < 1) { resetImportModal(); }
  }

  // UPLOAD FILES - Show File list after upload
  function showUploadedFileList() {
    // Hide dropzone
    $('#modal__import-data-dropzone').hide();
    $('#modal__import-data .loading').hide();
    $('#modal__import-data form').show();

    // Clear then populate file list
    $('#modal__import-filelist ul').html('');
    uploadedFiles.forEach(function(file) {
      var item = '<li data-file-id="' + file.id + '">' + file.filename +
      '<span data-file-id="' + file.id + '" class="delete-file">&times;</span></li>';
      $('#modal__import-filelist ul').append(item);
    });

    // Rebind delete buttons for files in file list
    $('.delete-file').click(removeFile);

    // Enable Import button
    $('#modal__import-data .modal__btn-primary').attr('disabled', false);

    // Show the file list
    $('#modal__import-filelist').show();
  }

  // UPLOAD FILES - Reset state of import data modal
  var resetImportModal = function(error) {
    // hide loading spinner show form
    $('#modal__import-data .loading').hide();
    $('#modal__import-data form').show();

    // Show dropzone, hide file list
    $('#modal__import-data-dropzone').show();
    $('#modal__import-filelist').hide();

    // Remove hidden input fields
    hiddenInputIds.forEach(function(id) { $('#' + id).remove(); });

    // Reset file input field
    $('#modal__import-data input[type="file"]').val('');
    $('#modal__import-data input[type="file"]').attr('disabled', false);

    // Disable import button
    $('#modal__import-data .modal__btn-primary').attr('disabled', true);

    // Reset callback counters
    uploadedFiles = [];
    uploadedFileCount = 0;
    hiddenInputIds = [];

    // Reset errors
    $('#modal__import-data .modal__error').html(error || '');
  }

  window.resetImportModal = resetImportModal;

  // UPLOAD FILES - Uploads the files using ActiveStorage
  function uploadFile(file) {
    $('#modal__import-data form').hide();
    $('#modal__import-data .loading').show();

    console.log('... file = ' + file.name);

    const url = $('#import_data_files').data('direct-upload-url');
    const upload = new DirectUpload(file, url);
    upload.create(function(error, blob) {
      if (error) {
        console.log(error);
        resetImportModal(error);
      }
      else {
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = 'data[files][]'
        hiddenField.id = 'file-' + blob.id;
        hiddenInputIds.push(hiddenField.id);
        $('#modal__import-data-content form').append(hiddenField)
        // console.log(blob.id);
        uploadedFiles.push(blob);

        // Check that all files have finished uploading
        uploadedFileCount--;
        if (uploadedFileCount === 0) { showUploadedFileList(); }
      }
    });
  }

  // UPLOAD FILES - Click to upload additional files
  $('#add-files').click(function() { $('#file-picker').click(); });

  // TURBOLINKS - Lock Scroll Position using data-turbolinks-permanent
  var scrollTops = {};
  $(document).bind('turbolinks:before-render', function() {
    $('[data-turbolinks-permanent]').each(function(index, element) {
      scrollTops[element] = element.scrollTop;
    });
  });

  $(document).bind('turbolinks:render', function() {
    $('[data-turbolinks-permanent]').each(function(index, element) {
      var selectedRow = $('table .is-active');
      if (scrollTops[element] && selectedRow.length) {
        element.scrollTop = scrollTops[element];
      }
    });
  });

});

// ACTIVE ROW AUTOSCROLL - Prevents from page loading with active row out of the scroll view
var scrollingPanel = $('.panel__patient-list');
if (!scrollingPanel.length) { scrollingPanel = $('.user-panel__list'); }

var selectedRow = $('table .is-active');
if (selectedRow.length) {
  scrollingPanel.scrollTop(selectedRow[0].offsetTop  - scrollingPanel[0].offsetTop);
}

const ROW_SIZE = 37;
const TABLE_HEADER_SIZE = 34;
const ROW_DELTA = ROW_SIZE / 3;

// ARROW NAVIGATION - Checks if TR is in view of the scrolling panel on the left
function isScrolledIntoView(elem, container) {
  var scrollViewTopOffset = container ? container.offset().top : 0;
  var scrollViewHeight = container ? container.height() : $(window).height();

  var scrollViewTop = scrollViewTopOffset + TABLE_HEADER_SIZE - ROW_DELTA;
  var scrollViewBottom = scrollViewTopOffset + scrollViewHeight + ROW_DELTA;

  var elemTop = $(elem).offset().top;
  var elemBottom = elemTop + $(elem).height();

  // console.log('     ');
  // console.log('Element Top:', elemTop);
  // console.log('Element Bottom:', elemBottom);
  // console.log('Element Height:', $(elem).height());
  // console.log('Document Top:', scrollViewTop);
  // console.log('Document Height:', $(window).height());
  // console.log('Document Bottom:', scrollViewBottom);
  // console.log('elemBottom <= scrollViewBottom:', elemBottom <= scrollViewBottom);
  // console.log('elemTop >= scrollViewTop:', elemTop >= scrollViewTop);
  // console.log('     ');

  return elemBottom <= scrollViewBottom && elemTop >= scrollViewTop;
}

// ARROW NAVIGATION - generic, works for both sample sets and users
var targetId;
$(document).keydown(function(e) {
  // Currently selected row
  var selectedRow = $('table .is-active');
  if (selectedRow.length && !targetId) {
    var scrollingPanel = $('.panel__patient-list');
    if (!scrollingPanel.length) { scrollingPanel = $('.user-panel__list'); }

    var tbodyElement = selectedRow.parents('tbody');
    var trElements = tbodyElement.find('tr');

    var nextElement, scrollOffset, scrollingUp;

    // Detect down and up key and calculate scrollOffset and next element
    switch (e.key) {
      case 'ArrowDown':
        nextElement = selectedRow.next('tr');
        if (nextElement.length) {
          scrollOffset = nextElement[0].offsetTop - ROW_SIZE;
        }
        else {
          nextElement = trElements.first();
          scrollOffset = 0;
        }
        break;
      case 'ArrowUp':
        scrollingUp = true;
        nextElement = selectedRow.prev('tr');
        if (nextElement.length) {
          scrollOffset = nextElement[0].offsetTop + TABLE_HEADER_SIZE - scrollingPanel.height();
        }
        else {
          nextElement = trElements.last();
          scrollOffset = nextElement[0].offsetTop + scrollingPanel[0].offsetTop + scrollingPanel.height();
        }
        break;
      default: break;
    }

    if (nextElement && nextElement.length) {
      targetId = nextElement.data('result-id') || nextElement.data('user-id');

      // Visit the link
      nextElement.find('a')[0].click();

      // Adjust scroll position of table
      if (!isScrolledIntoView(nextElement, scrollingPanel)) {
        scrollingPanel.scrollTop(scrollOffset);
      }
    }
  }
})
.keyup(function(e) {
  targetId = null;
});

